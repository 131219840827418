import React, { useState } from "react"
import styled from "styled-components"
import { ExternalLink } from "../Button"

const CardHover = styled.div`
  opacity: ${(props) => (props.isHoverCardVisible ? 1 : 0)};
  transform: translateX(${(props) => (props.isHoverCardVisible ? 0 : "-100%")});
  transition: all 0.2s ease-in-out;
`

const ImageWrapper = styled.div`
  position: relative;

  img {
    filter: grayscale(100%);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      #card-wrapper-hover {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
`

const TeamMemberInfo = ({ linkedInId }) => (
  <div
    className="flex flex-col h-full text-white
  "
  >
    <div className="flex h-full items-center justify-center">
      <ExternalLink label="Linkedin" url={`//linkedin.com/${linkedInId}`} />
    </div>
  </div>
)

const FellowsCard = ({ name, title, focus, linkedInId, images }) => {
  const [isHoverCardVisible, setIsHoverCardVisible] = useState(false)

  return (
    <div
      className="flex flex-col justify-start"
      onTouchEnd={() => setIsHoverCardVisible(!isHoverCardVisible)}
    >
      <div className={`relative w-full mx-auto mb-3 overflow-hidden`}>
        <span className="flex relative h-full mx-auto">
          <ImageWrapper>
            <img src={images.thumbnail.url} className="mx-auto" />

            <CardHover
              id="card-wrapper-hover"
              className="absolute top-0 w-full h-full bg-mvp-dark-blue-70"
              isHoverCardVisible={isHoverCardVisible}
            >
              <TeamMemberInfo linkedInId={linkedInId} />
            </CardHover>
          </ImageWrapper>
        </span>
      </div>

      <div
        className="text-mvp-dark-blue text-left"
        onMouseEnter={() => setIsHoverCardVisible(true)}
        onMouseLeave={() => setIsHoverCardVisible(false)}
      >
        <div className="font-bold tracking-wide uppercase">{name}</div>
        <div className="tracking-wide">{title}</div>
        <div className="tracking-wide leading-none">{focus}</div>
      </div>
    </div>
  )
}

export default FellowsCard
