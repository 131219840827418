import React from "react"
import { Link } from "gatsby"

const Button = ({ label, page, isActive, colour = "blue", className, url }) => {
  if (colour == "blue") {
    if (url) {
      return (
        <a
          href={url}
          target="_blank"
          className={`
        inline-block
        py-2 px-4
        border border-mvp-dark-blue rounded-full
        uppercase tracking-widest text-15px
        transition transition-colors duration-500 ease-in-out
        hover:text-white hover:bg-mvp-dark-blue 
        ${isActive ? "text-white bg-mvp-dark-blue" : "cursor-pointer"}
        ${className}`}
        >
          {label}
        </a>
      )
    }
    return (
      <Link
        to={page}
        className={`
        inline-block
        py-2 px-4
        border border-mvp-dark-blue rounded-full
        uppercase tracking-widest text-15px
        transition transition-colors duration-500 ease-in-out
        hover:text-white hover:bg-mvp-dark-blue 
        ${isActive ? "text-white bg-mvp-dark-blue" : "cursor-pointer"}
        ${className}`}
      >
        {label}
      </Link>
    )
  }

  if (colour == "light") {
    return (
      <Link
        to={page}
        className={`
        inline-block
        py-2 px-4
        border border-mvp-light-blue rounded-full
        uppercase tracking-widest text-15px
        transition transition-colors duration-500 ease-in-out
        hover:text-mvp-dark-blue hover:bg-mvp-light-blue
        ${isActive ? "text-mvp-dark-blue bg-mvp-light-blue" : "cursor-pointer"}
        ${className}`}
      >
        {label}
      </Link>
    )
  }

  if (colour == "grey") {
    return (
      <Link
        to={page}
        className={`
        inline-block
          py-2 px-4
        border  rounded-full
        uppercase tracking-widest text-15px 
        transition transition-colors duration-500 ease-in-out
        hover:text-white hover:bg-mvp-dark-blue  hover:border-mvp-dark-blue
        ${
          isActive
            ? "text-white bg-mvp-dark-blue border-mvp-dark-blue"
            : "text-mvp-light-grey border-mvp-light-grey cursor-pointer"
        }
        ${className}`}
      >
        {label}
      </Link>
    )
  }
}

export const ExternalLink = ({ url, isActive, className, label }) => (
  <a
    href={url}
    target="_blank"
    className={`
    inline-block
    py-2 px-4
    border border-mvp-light-blue rounded-full
    uppercase tracking-widest text-15px
    transition transition-colors duration-500 ease-in-out
    hover:text-mvp-dark-blue hover:bg-mvp-light-blue
    cursor-pointer
    ${isActive ? "text-mvp-dark-blue bg-mvp-light-blue" : ""}
    ${className}`}
  >
    {label}
  </a>
)

export default Button
