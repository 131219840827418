import React, { useContext } from "react"
import { sortBy } from "lodash"
import { graphql } from "gatsby"

import FellowsGrid from "../../components/Team/FellowsGrid"
import { MetaTagsContext } from "../../layouts"

const ExecutiveFellows = ({ data }) => {
  const cmsTeamData = data.strapiTeam
  const cmsFellowsData = data.allStrapiExecutiveFellows.nodes
  const sortedFellows = sortBy(cmsFellowsData, ["displayOrder"])

  const { setMetaTags } = useContext(MetaTagsContext)
  setMetaTags(data.strapiTeam.seoFellows)

  return (
    <div className="bg-white">
      <div className="md:py-16">
        <FellowsGrid
          fellows={sortedFellows}
          introductionTitle={cmsTeamData.fellowsIntroductionTitle}
          introductionDescription={cmsTeamData.fellowsIntroductionDescription}
        />
      </div>
    </div>
  )
}

export const query = graphql`
  {
    allStrapiExecutiveFellows {
      nodes {
        title
        name
        linkedInId
        displayOrder
        company
        bio
        focus
        headshot {
          url
          ext
          formats {
            thumbnail {
              ext
              url
            }
            small {
              url
              ext
            }
            medium {
              ext
              url
            }
            large {
              ext
              url
            }
          }
        }
      }
    }
    strapiTeam {
      fellowsIntroductionTitle
      fellowsIntroductionDescription
      seoFellows {
        title
        description
      }
    }
  }
`

export default ExecutiveFellows
