import React from "react"

import FellowsCard from "./FellowsCard"

const FellowsGrid = ({
  fellows,
  introductionTitle,
  introductionDescription,
}) => {
  return (
    <div className="container py-10 md:py-0">
      <div className="flex flex-col w-full">
        <div className="pb-10 lg:pb-16">
          <div
            className="max-w-xl font-light text-36px leading-tight
            lg:max-w-3xl lg:text-4-5xl"
          >
            {introductionTitle}
          </div>

          <div
            className="py-4 max-w-screen-md
                md:pb-0
                lg:text-24px"
          >
            {introductionDescription}
          </div>
        </div>
      </div>

      <div
        className="grid grid-cols-2 gap-y-10 gap-x-10 justify-items-center
        sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 
      "
      >
        {fellows &&
          fellows.map((member) => {
            return (
              <FellowsCard
                name={member.name}
                title={member.title}
                focus={member.focus}
                linkedInId={member.linkedInId}
                images={member.headshot.formats}
              />
            )
          })}
      </div>
    </div>
  )
}

export default FellowsGrid
